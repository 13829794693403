// Imports => API
import { BridgeAPI } from '@api/bridge.api'
import { BuildingBlocksAPI } from '@api/building-blocks.api'
import { API_CLIENT } from '@api/client.api'
import { FormsAPI } from '@api/forms.api'
import { PagesAPI } from '@api/pages.api'
import { PostsAPI } from '@api/posts.api'
import { RoundtripsAPI } from '@api/roundtrips.api'
import { SettingsAPI } from '@api/settings.api'
import { SitesAPI } from '@api/sites.api'

import { DotmailerAPI } from './dotmailer.api'
import { PostTypeChunksAPI } from './post-type-chunks'
import { ProductFiltersAPI } from './product-filters.api'
import { TravelPlanAPI } from './travel-plan.api'

export class API_Instance {
  static Bridge
  static BuildingBlocks
  static Forms
  static Pages
  static Posts
  static Roundtrips
  static Sites
  static Settings
  static ProductFilters
  static PostTypeChunksAPI
  static TravelPlanAPI
  static DotmailerAPI

  static get;
  static post
  static put
  static patch
  static delete

  constructor() {
    const Client = API_CLIENT

    this.Bridge = new BridgeAPI({ Client })
    this.BuildingBlocks = new BuildingBlocksAPI({ Client })
    this.Forms = new FormsAPI({ Client })
    this.Pages = new PagesAPI({ Client })
    this.Posts = new PostsAPI({ Client })
    this.Roundtrips = new RoundtripsAPI({ Client })
    this.Sites = new SitesAPI({ Client })
    this.Settings = new SettingsAPI({ Client })
    this.ProductFilters = new ProductFiltersAPI({ Client })
    this.PostTypeChunks = new PostTypeChunksAPI({ Client })
    this.TravelPlan = new TravelPlanAPI({ Client })
    this.Dotmailer = new DotmailerAPI({ Client })

    this.get = Client.get
    this.post = Client.post
    this.put = Client.put
    this.patch = Client.patch
    this.delete = Client.delete
  }
}

export const API = new API_Instance()
