import { ENDPOINTS } from '@constants/endpoints.constants'

export class FormsAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = (params = {}) => {
    return this.Client.get(ENDPOINTS.FORMS.INDEX, params).then(
      response => response
    )
  }

  show = (id, params = {}) => {
    return this.Client.get(ENDPOINTS.FORMS.SHOW(id), params).then(
      response => response
    )
  }

  submit = ({ formId, payload, params }) => {
    return this.Client.post(
      ENDPOINTS.FORMS.SUBMIT(formId),
      payload,
      params
    ).then(response => response)
  }
}
