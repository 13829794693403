import { API_CONFIG } from '@config/api.config'
import { ENDPOINTS } from '@constants/endpoints.constants'

export class RoundtripsAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = (destination = '', params = {}) => {
    return this.Client.get(ENDPOINTS.ROUNDTRIPS.INDEX, params, {
      ...API_CONFIG.options,
      destination,
    }).then(response => response)
  }

  showCategory = (id, params = {}) => {
    return this.Client.get(ENDPOINTS.ROUNDTRIPS.SHOW_CATEGORY(id), params).then(
      res => res.data
    )
  }

  show = (id, params = {}) => {
    return this.Client.get(ENDPOINTS.ROUNDTRIPS.SHOW(id), params).then(
      res => res
    )
  }
}
