import { Memoize } from '@utilities/helpers/memoize.helpers'

const serializeQueryString = Memoize(obj => {
  const str = []
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return str.join('&')
})

// Simple utility to generate correct querystring
export const getQueryString = Memoize(params => {
  if (!params) return null // empty string

  const querystring = serializeQueryString(params)

  return querystring
})
