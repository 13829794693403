interface GlobalRefType {
  [key: string]: any
}

export class GlobalRef<T> {
  private readonly sym: symbol

  constructor(uniqueName: string) {
    this.sym = Symbol.for(uniqueName)
  }

  get value() {
    return (global as any)[this.sym] as T | undefined
  }

  set value(value: T) {
    ;(global as any)[this.sym] = value
  }
}

export const setGlobalRef = (value, key = 'rnxt.instance') => {
  const Ref = new GlobalRef(key)

  Ref.value = value
}

export const getGlobalRef = (key = 'rnxt.instance') => {
  const Ref = new GlobalRef(key)

  return Ref?.value as GlobalRefType
}
