import { ENDPOINTS } from '@constants/endpoints.constants'

export class ProductFiltersAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = (params = {}) => {
    return this.Client.get(ENDPOINTS.PRODUCT_FILTERS.INDEX, params).then(
      response => response.data
    )
  }

  show = (id, params = {}) => {
    return this.Client.get(ENDPOINTS.PRODUCT_FILTERS.SHOW(id), params).then(
      response => response.data
    )
  }
}
