import { ENDPOINTS } from '@constants/endpoints.constants'

export class TravelPlanAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  show = ({ uid, travelPlanId }, params = {}) => {
    return this.Client.get(
      ENDPOINTS.TRAVEL_PLAN.SHOW({
        uid,
        travelPlanId,
      }),
      params
    ).then(response => response.data)
  }
}
