import { ENDPOINTS } from '@constants/endpoints.constants'

import { API_CONFIG } from '@/config/api.config'

export class PagesAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = (destination = '', params = {}) => {
    return this.Client.get(ENDPOINTS.PAGES.INDEX, params, {
      ...API_CONFIG.options,
      destination,
    }).then(response => response)
  }

  show = (id, params = {}) => {
    return this.Client.get(ENDPOINTS.PAGES.SHOW(id), params).then(
      response => response
    )
  }
}
