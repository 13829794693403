import { ENDPOINTS } from '@constants/endpoints.constants'

export class DotmailerAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  submit = ({ payload, params = {} }) => {
    return this.Client.post(ENDPOINTS.DOTMAILER.SUBMIT, payload, params).then(
      response => response
    )
  }
}
