import { ENDPOINTS } from '@constants/endpoints.constants'

export class SitesAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = (params = {}) => {
    return this.Client.get(
      ENDPOINTS.SITES.INDEX,
      params,
      { next: { revalidate: 7200 } },
      false
    ).then(response => response.data)
  }
}
